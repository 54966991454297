/**
 * Variables
 * ----------------------------------------------------------------------------
 */

/* Colors */
$alabaster: #fafafa;
$gallery: #ededed;
$gray: #818181;
$mine-shaft: #000000;
$picton-blue: #30b007; /*#0cc452;*/
$bright-blue: #13d705;
$silver: #c6c6c6;
$tundora: #474747;
$white: #ffffff;






